<template lang="pug">
BasePane
  .sidebar-label {{ $t('columnCount') }}
  .ds-column-count.mt-1
    ColumnSplitSvg(
      :layout="[12]"
      @click.native="setColumnCount(1)"
      :class="{ selected: columnCount === 1 }"
    )
    ColumnSplitSvg(
      :layout="[6, 6]"
      @click.native="setColumnCount(2)"
      :class="{ selected: columnCount === 2 }"
    )
    ColumnSplitSvg(
      :layout="[4, 4, 4]"
      @click.native="setColumnCount(3)"
      :class="{ selected: columnCount === 3 }"
    )
  template(v-if="columnCount > 1")
    .sidebar-spacing-y
    .sidebar-label {{ $t('columnSplit') }}
    .ds-column-splits.mt-1
      column-split-svg(
        v-for="(layout, index) in possibleLayouts"
        :layout="layout"
        @click.native="setColumnSplit(layout)"
        :class="{ selected: isLayoutSelected(layout) }"
        :key="index"
      )
  .sidebar-spacing-y
    .sidebar-label.mb-1 {{ $t('columnsWithinRow') }}
    .ds-col-in-row.d-flex.cursor-pointer(
      v-for="(col, index) in selectedRowColumns"
      small
      secondary
      block
      @click="selectColumn(col)"
      :key="index"
      :class="{ 'mt-2': index }"
    )
      ColumnSplitSvg(:layout="columnsInBlockLayout")
      .w-100.d-flex.align-items-center.justify-content-between
        .sidebar-label {{ columnText(index) }}
        UilAngleRightB
  hr.mt-4.mb-4
  BackgroundAndBorder(
    typeOfComponent="row"
    :colorLabel="$t('block')"
    shadow
    :deviceSelector="false"
  )
    template(#afterBackground)
      OmColorInput(
        :label="$t('page')"
        property="selectedPage.$device.background.color"
        typeOfComponent="global"
        layout="col"
        dsAllowed
      )
  hr.mt-1.mb-5
  Accordion(fix)
    template(#title) {{ $t('sizeAndSpacing') }}
    Margin.mb-2
    RangeInput(
      :label="$t('minHeight')"
      v-model.number="minHeight"
      :min="0"
      :max="2000"
      :step="1"
      :placeholder="mobileMinHeightPlaceholder"
      editMobile
      fixUnit
    )
  hr.my-3
  template(#advancedContent)
    Padding.mb-2
    Heading(label) {{ $t('reverseOrderMobile') }}
      template(#prependEnding)
        OmSwitch#mobile-reversed-order(:value="reverseOrder" @switch="reverseOrder = $event")
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>

<script>
  import { mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import itemMixin from '@/editor/mixins/item';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import ColumnSplitSvg from '@/editor/components/svg/ColumnSplitSvg.vue';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import columnMixin from '@/editor/mixins/column';
  import { UilAngleRightB } from '@iconscout/vue-unicons';
  import BasePane from './BasePane.vue';

  const workspaceStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    return workspace.contentWindow.om.store;
  };

  export default {
    components: {
      BasePane,
      Accordion,
      Heading,
      RangeInput,
      Margin,
      Padding,
      Hide,
      BackgroundAndBorder,
      ColumnSplitSvg,
      UilAngleRightB,
    },
    mixins: [itemMixin, columnMixin],
    data: () => ({
      canEditMobile: true,
      selectedRowColumns: [],
    }),
    computed: {
      ...mapState(['selectedRow', 'mobilePreview']),
      columnsInBlockLayout() {
        if ([1, '1'].includes(this.columnCount)) return [12];
        return this.columnCount === 2 ? [6, 6] : [4, 4, 4];
      },
      possibleLayouts() {
        if (this.columnCount === 2)
          return [
            [3, 9],
            [4, 8],
            [5, 7],
            [6, 6],
            [7, 5],
            [8, 4],
            [9, 3],
          ];
        if (this.columnCount === 3)
          return [
            [4, 4, 4],
            [6, 3, 3],
            [3, 6, 3],
            [3, 3, 6],
          ];
        return [];
      },
      columnCount: {
        get() {
          return this.selectedRow.data.columnSplit.length;
        },
        set(value) {
          const changeValue = () => {
            this.$bus.$emit('updateColumnCount', { uid: this.selectedRow.uid, count: value });
          };

          this.$nextTick(() => {
            const cols = this.columnsOfRow(this.selectedRow.uid).splice(value);
            let colHasElement = false;
            for (const col of cols) {
              colHasElement = this.elementsOfColumn(col.uid).length;
              if (colHasElement) break;
            }

            if (this.columnCount > value && colHasElement) {
              this.$modal.show('dialog', {
                text: this.$t('columnCountWarning'),
                buttons: [
                  {
                    title: this.$t('yes'),
                    class: getBrandedClassString({ primary: true }, 'mr-3'),
                    handler: () => {
                      changeValue();
                      this.$modal.hide('dialog');
                    },
                  },
                  {
                    title: this.$t('cancel'),
                    class: getBrandedClassString({ secondary: true }),
                    default: true,
                  },
                ],
              });
            } else {
              changeValue();
            }
          });
        },
      },
      minHeight: {
        get() {
          return this.getValueOf('$device.minHeight');
        },
        set(value) {
          this.setValueOf('$device.minHeight', value);
        },
      },
      mobileMinHeightPlaceholder() {
        if (this.mobilePreview && !this.getValueOf('mobile.minHeight')) {
          return `${this.getValueOf('desktop.minHeight', 0)}`;
        }
        return undefined;
      },
      reverseOrder: {
        get() {
          return this.getValueOf('mobile.reverseOrder');
        },
        set(value) {
          return this.setValueOf('mobile.reverseOrder', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
    },
    watch: {
      columnCount() {
        this.selectedRowColumns = this.columnsOfRow(this.selectedRow.uid);
      },
    },
    created() {
      this.selectedRowColumns = this.columnsOfRow(this.selectedRow.uid);
    },
    methods: {
      setColumnCount(count) {
        if (this.columnCount !== count) this.columnCount = count;
        this.resetCustomColWidths();
      },
      selectColumn(column) {
        this.$bus.$emit('selectElement', column ? column.uid : null);
      },
      resetCustomColWidths() {
        this.selectedRowColumns.forEach((c) => {
          workspaceStore().dispatch('updateStyleProperty', {
            uid: c.uid,
            property: 'maxWidth',
            value: null,
          });
        });
      },
      columnsOfRow(id) {
        if (window.om.template) {
          return window.om.template.elements.filter((e) => e.type === 'OmCol' && e.rowId === id);
        }
        return [];
      },
      elementsOfColumn(colId) {
        if (!window.om.template) {
          return [];
        }

        return window.om.template.elements.filter(
          (e) =>
            e.type !== 'OmCol' &&
            e.type !== 'OmRow' &&
            e.type !== 'OmPage' &&
            !e.isTeaser &&
            e.colId === colId,
        );
      },
      isLayoutSelected(layout) {
        return layout.toString() === this.selectedRow.data.columnSplit.toString();
      },
      setColumnSplit(value) {
        this.selectedRow.data.columnSplit = value;
        this.resetCustomColWidths();
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .sidebar
    &-label
      font-size: .75rem
      color: $om-dark-grey-3
    .column-split-item
      margin: 0
      margin-right: 0
      width: 3.5rem
      height: 2.5rem
      padding: .5625rem .875rem
      border: 1px solid #E3E5E8
      &.selected
        background: #F1F2F3
    .ds-col-in-row
      border: 1px solid #E3E5E8
      border-radius: .5rem
      color: $om-ds-gray
      padding-right: 1rem
      .column-split-item
        border: 0
    .ds-column-count,
    .ds-column-splits
      width: 11.5rem
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      gap: .5rem
</style>
